import abyrtus from '@/assets/images/consultants/abyrtus.jpg';
import amichalska from '@/assets/images/consultants/amichalska.jpg';
import bosrodka from '@/assets/images/consultants/bosrodka.jpg';
import dwisniewski from '@/assets/images/consultants/dwisniewski.jpg';
import gstalica from '@/assets/images/consultants/gstalica.jpg';
import kbobak from '@/assets/images/consultants/kbobak.jpg';
import kklisz from '@/assets/images/consultants/kklisz.jpg';
import mwator from '@/assets/images/consultants/mwator.jpg';
import nparuzel from '@/assets/images/consultants/nparuzel.jpg';
import plech from '@/assets/images/consultants/plech.jpg';
import psiwy from '@/assets/images/consultants/psiwy.jpg';
import wpieczka from '@/assets/images/consultants/wpieczka.jpg';
import wtracz from '@/assets/images/consultants/wtracz.jpg';
import plasek from '@/assets/images/consultants/plasek.jpg';
import dpasieka from '@/assets/images/consultants/dpasieka.jpg';
import jnowak from '@/assets/images/consultants/jnowak.jpg';

export default [
    {
        email: "abyrtus@vehis.pl",
        img: abyrtus
    },
    {
        email: "amichalska@vehis.pl",
        img: amichalska,
    },
    {
        email: "bosrodka@vehis.pl",
        img: bosrodka
    },
    {
        email: "dwisniewski@vehis.pl",
        img: dwisniewski
    },
    {
        email: "gstalica@vehis.pl",
        img: gstalica
    },
    { 
        email: "kbobak@vehis.pl", 
        img: kbobak }
    ,
    { 
        email: "kklisz@vehis.pl", 
        img: kklisz 
    },
    { 
        email: "mwator@vehis.pl", 
        img: mwator 
    },
    { 
        email: "nparuzel@vehis.pl", 
        img: nparuzel 
    },
    { 
        email: "plech@vehis.pl", 
        img: plech 
    },
    { 
        email: "psiwy@vehis.pl", 
        img: psiwy 
    },
    { 
        email: "wpieczka@vehis.pl", 
        img: wpieczka 
    },
    { 
        email: "wtracz@vehis.pl", 
        img: wtracz 
    },
    {
        email: "plasek@vehis.pl",
        img: plasek
    },
    {
        email: "jnowak@vehis.pl",
        img: jnowak
    },
    {
        email: "dpasieka@vehis.pl",
        img: dpasieka
    }
]