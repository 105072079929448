<script setup>
const props = defineProps({
  right: String,
  headerRightClass: String
})
</script>

<template>
<div class="text-2xl border-b border-b-black pb-2 flex flex-wrap justify-between items-end gap-1">
  <div><slot/></div>
  <div v-if="right" :class="props.headerRightClass ? props.headerRightClass : 'font-extralight text-xl'">{{ right }}</div>
</div>
</template>
