<script setup>
const props = defineProps({
  car: {
    type: Object,
    default: () => ({})
  },
  offer: {
    type: Object,
    default: () => ({})
  },
  imageOnScroll: {
    type: Number,
    default: null
  },
  noTop: Boolean,
  noAdjust: Boolean,
  noButton: Boolean
})

const {onChange, leasingDrawerOpened} = useLeasingParams();
const openFinancingDrawer = () => {
  leasingDrawerOpened.value = true;
}
</script>

<template>
  <div class="py-4 select-none w-full lg:w-[300px] lg:min-w-[300px] sticky z-20 top-20 transition-all"
       :class="{'top-20': !leasingDrawerOpened, 'lg:top-60 xl:top-40':leasingDrawerOpened}">
    <CarCardLiczak :car="props.car"
                   :imageOnScroll="imageOnScroll"
                   @adjust="openFinancingDrawer"
                   get-offer
                   :noTop="noTop"
                   :noAdjust="noAdjust"
                   :offer="offer"
                   :no-button="noButton"
    />
  </div>

</template>
